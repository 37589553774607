import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, inject, Injectable } from '@angular/core';

import { RouterService } from '@/shared/services/router.service';
import { SnackBarService } from '@/shared/services/snackbar.service';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {
  readonly router = inject(RouterService);
  readonly snackBar = inject(SnackBarService);

  handleError(error: unknown): void {
    if (error instanceof HttpErrorResponse) {
      // Handle according to the HTTP status code
      switch (error.status) {
        case 400:
          this.handleBadRequest();
          break;
        case 403:
          this.handleForbidden();
          break;
        case 404:
          this.handleNotFound();
          break;
        case 500:
          this.handleServerError();
          break;
        default:
          this.handleUncaughtError(error);
          break;
      }
    } else {
      // Uncaught error.
      this.handleUncaughtError(error);
    }
  }

  private handleBadRequest(): void {
    this.snackBar.error('Bad request. Please check your input.');
  }

  private handleForbidden(): void {
    this.snackBar.error(
      'Forbidden. You do not have permission to access this resource.'
    );
  }

  private handleNotFound(): void {
    this.router.navigateToPMNotFound();
  }

  private handleServerError(): void {
    this.snackBar.error('Server error. Please try again later.');
  }

  private handleUncaughtError(error: unknown): void {
    this.snackBar.error('Oops, an error occurred. Please try again.');
    console.error('Uncaught error:', error);
  }
}
