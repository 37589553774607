import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';

import { DataEverywhereComponent } from './components/data-everywhere.component';
import { FeaturesComponent } from './components/features.component';
import { FooterComponent } from './components/footer.component';
import { HeaderComponent } from './components/header.component';
import { HeroSectionComponent } from './components/hero-section.component';
import { HowItWorksComponent } from './components/how-it-works.component';
import { NavLinksComponent } from './components/nav-links.component';
import { NewsletterBannerComponent } from './components/newsletter-banner.component';
import { TryNowBanner2Component } from './components/try-now-banner-2.component';
import { TryNowBannerComponent } from './components/try-now-banner.component';
import { VideosComponent } from './components/videos.component';

@Component({
  selector: 'ds-lp-landing-page',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DataEverywhereComponent,
    FeaturesComponent,
    FooterComponent,
    HeaderComponent,
    HeroSectionComponent,
    HowItWorksComponent,
    MatSidenavModule,
    NavLinksComponent,
    NewsletterBannerComponent,
    TryNowBannerComponent,
    TryNowBanner2Component,
    VideosComponent
  ],
  template: `
    <section class="relative overflow-y-visible">
      <ds-lp-header (toggleSidenavEvent)="isSidenavOpen = !isSidenavOpen" />

      <mat-sidenav-container [hasBackdrop]="true">
        <mat-sidenav
          [autoFocus]="false"
          class="!text-purple-500 p-8 !z-10 !top-16"
          [fixedInViewport]="true"
          [(opened)]="isSidenavOpen"
        >
          <ds-lp-nav-links
            [horizontal]="true"
            color="purple"
            (clickedLinkEvent)="isSidenavOpen = false"
          />
        </mat-sidenav>

        <mat-sidenav-content>
          <ds-lp-hero-section />
          @defer {
            <ds-lp-how-it-works />
            <ds-lp-data-everywhere />
            <ds-lp-try-now-banner />
            <ds-lp-videos />
            <ds-lp-features />
            <ds-lp-try-now-banner-2 />
            <ds-lp-newsletter-banner />
            <ds-lp-footer />
          }
        </mat-sidenav-content>
      </mat-sidenav-container>
    </section>
  `,
  styles: [
    `
      mat-sidenav {
        border-radius: 0 32px 32px 0;
      }
    `
  ]
})
export class LandingPageComponent {
  isSidenavOpen = false;
  title = 'Power Your NLP Projects with Accurate Annotations';
  subtitle = 'The ultimate tool to streamline your NLP data labeling process.';
}
