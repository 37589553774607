import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { DragScrollItemDirective } from 'ngx-drag-scroll';

@Component({
  selector: 'ds-lp-carousel-img',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DragScrollItemDirective, NgOptimizedImage],
  template: `
    <div drag-scroll-item class="p-4">
      @if (hasPriority()) {
        <img
          class="rounded-2xl"
          [alt]="caption()"
          [ngSrc]="imgUrl()"
          width="220"
          height="476"
          priority
        />
      } @else {
        <img
          class="rounded-2xl"
          [alt]="caption()"
          [ngSrc]="imgUrl()"
          width="220"
          height="476"
        />
      }
      <div
        class="flex-shrink-0 text-wrap text-sm font-medium text-center mx-auto mt-3 !text-white "
      >
        {{ caption() }}
      </div>
    </div>
  `,
  styles: ``
})
export class CarouselImgComponent {
  imgUrl = input.required<string>();
  caption = input.required<string>();
  hasPriority = input<boolean>(false);
}
