import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  output
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';

import { UrlsService } from '@/shared/services/urls.service';

@Component({
  selector: 'ds-lp-nav-links',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, NgClass, RouterLink],
  template: `
    <div
      class="!flex gap-4 w-auto "
      [ngClass]="horizontal() ? '!flex-col' : '!flex-row'"
    >
      @for (navLink of navLinks; track navLink) {
        <button
          mat-button
          matListItemMeta
          [routerLink]="'./'"
          [fragment]="navLink.fragment"
          [ngClass]="color() === 'white' ? '!text-white' : '!text-purple-500'"
          (click)="clickedLinkEvent.emit()"
        >
          {{ navLink.name }}
        </button>
      }
      <button
        mat-button
        [ngClass]="color() === 'white' ? '!text-white' : '!text-purple-500'"
        [routerLink]="urls.LOGIN_URL"
      >
        Sign in
      </button>
      <button mat-raised-button color="primary" [routerLink]="urls.SIGNUP_URL">
        Start for free
      </button>
    </div>
  `,
  styles: ``
})
export class NavLinksComponent {
  horizontal = input<boolean>(false);
  color = input.required<'white' | 'purple'>();

  clickedLinkEvent = output<void>();

  navLinks = [
    { name: 'How it works', fragment: 'how-it-works' },
    { name: 'Video', fragment: 'video' },
    { name: 'Features', fragment: 'features' }
  ];

  readonly urls = inject(UrlsService);
}
