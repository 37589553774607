import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { HasHttpRequestService } from './has-http-request.service';

@Injectable()
export class HasHttpRequestInterceptor implements HttpInterceptor {
  constructor(private service: HasHttpRequestService) {}

  /* eslint-disable @typescript-eslint/no-explicit-any */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.service.startRequest();
    return next
      .handle(req)
      .pipe(finalize(() => this.service.finalizeRequest()));
  }
}
