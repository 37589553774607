import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

import { RaisedButtonComponent } from '@/shared/components/raised-button.component';
import { UrlsService } from '@/shared/services/urls.service';

import { AnimatedCirclesBackgroundComponent } from './animated-circles-background.component';
import { ScreenshotCarouselComponent } from './screenshot-carousel.component';

@Component({
  selector: 'ds-lp-hero-section',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,
    MatIconModule,
    MatButtonModule,
    ScreenshotCarouselComponent,
    RaisedButtonComponent,
    AnimatedCirclesBackgroundComponent
  ],
  template: `
    <section class="relative w-full animated-bg-purple flex flex-col">
      <!-- Hero Section -->
      <div class="max-w-[1000px] m-auto p-8">
        <div
          class="w-full flex flex-col md:flex-row justify-between gap-8 sm:gap-14 items-center"
        >
          <div class="flex flex-col justify-between gap-8 z-10 py-8">
            <div>
              <h1 class="lato !text-4xl text-white text-center md:text-left">
                Effortless Data Labeling for NLP
              </h1>
              <h2 class="lato !text-2xl text-pink-200 text-center md:text-left">
                Fast, Intuitive, and Code-Free
              </h2>
            </div>
            <p class="text-lg md:text-xl text-white opacity-80">
              Built for data scientists and small teams, easily
              <span class="font-semibold">create and label NLP datasets</span>
              with <span class="font-semibold">LLM-powered suggestions</span>.
              Manage the entire workflow —
              <span class="font-semibold">from setup to export</span> — all in
              one seamless platform.
            </p>
            <p class="text-lg md:text-xl text-white opacity-80">
              Best of all?
              <span class="font-semibold">No coding</span> required.
            </p>
            <div class="text-center md:text-left">
              <ds-raised-button
                text="Try it now! It's free"
                buttonClass="!py-8 !px-8 !text-lg"
                class="!mt-4"
                [routerLink]="urls.SIGNUP_URL"
              />
            </div>
          </div>
          <ds-lp-animated-circles-background />
          <ds-lp-screenshot-carousel class="z-10" />
        </div>
      </div>
    </section>
  `,
  styles: `
    section {
      min-height: calc(100vh - 70px); /* 70px is the header height */
    }
  `
})
export class HeroSectionComponent {
  readonly urls = inject(UrlsService);
}
