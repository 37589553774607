import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

import { NavLinksComponent } from './nav-links.component';

@Component({
  selector: 'ds-lp-header',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    MatIconModule,
    NavLinksComponent,
    NgOptimizedImage,
    RouterLink
  ],
  template: `
    <header class="w-full sticky top-0 animated-bg-purple z-20 flex">
      <div
        class="max-w-[1000px] mx-auto flex w-full justify-between items-center py-2 px-6"
      >
        <a [routerLink]="'.'" class="hover:brightness-90">
          <img
            class="logo"
            alt="Data Stand logo"
            ngSrc="assets/img/logo-white.webp"
            width="144"
            height="28"
            priority
          />
        </a>
        <!-- Mobile menu: show hamburguer -->
        <div class="block md:hidden">
          <button
            mat-icon-button
            aria-label="Menu"
            (click)="toggleSidenavEvent.emit()"
          >
            <mat-icon class="text-white">menu</mat-icon>
          </button>
        </div>

        <!-- Big screen menu: show navlinks -->
        <div class="hidden md:block">
          <ds-lp-nav-links color="white" />
        </div>
      </div>
    </header>
  `,
  styles: `
    :host {
      position: relative;
    }
    header {
      box-shadow:
        0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
      height: 70px;
    }
  `
})
export class HeaderComponent {
  toggleSidenavEvent = output<void>();
}
