import { Routes } from '@angular/router';

import { AuthGuard } from './shared/guards/auth.guard';

import { LandingPageComponent } from './landing-page/landing-page.component';

export const ROUTES: Routes = [
  // We intentionally eager load the landing page component.
  // There are pros and cons to this approach:
  //
  // Pros:
  // - Helps with SEO and reduces time to first meaningful paint.
  // - It is the primary access point to the platform.
  // - It is a static page, so it can be loaded quickly.
  //
  // Cons:
  // - It is not always needed, e.g. if user bookmarks the app page.
  //
  // See: https://github.com/mgechev/angular-performance-checklist?tab=readme-ov-file#dont-lazy-load-the-default-route
  {
    path: '',
    component: LandingPageComponent,
    title: 'Data Stand | AI-Powered NLP Data Labeling & Dataset Creation'
  },
  {
    path: '',
    loadChildren: () => import('./app/account/routes').then((m) => m.ROUTES)
  },
  {
    path: 'app',
    loadChildren: () => import('./app/routes').then((m) => m.ROUTES),
    canActivate: [AuthGuard]
  },
  {
    path: 'docs',
    loadChildren: () => import('./docs/routes').then((m) => m.ROUTES),
    data: { preload: false }
  },
  // Redirect not found to the landing page
  { path: '**', redirectTo: '' }
];
