import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll';

import { CarouselImgComponent } from './carousel-img.component';

@Component({
  selector: 'ds-lp-screenshot-carousel',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DragScrollComponent, NgOptimizedImage, CarouselImgComponent],
  template: `
    <section class="flex flex-col items-center self-center">
      <img
        ngSrc="assets/img/stand-top.webp"
        width="320"
        height="85"
        alt="Stand top"
        priority
      />
      <drag-scroll
        class="flex-shrink-0 max-w-[320px] flex items-center"
        [snap-duration]="300"
      >
        <!-- High priority images -->
        @for (img of imgs.slice(0, 2); track img.src; let index = $index) {
          <ds-lp-carousel-img
            [imgUrl]="img.src"
            [caption]="img.name"
            [hasPriority]="true"
          />
        }
        @defer {
          <!-- Low priority images -->
          @for (img of imgs.slice(2); track img.src; let index = $index) {
            <ds-lp-carousel-img
              [imgUrl]="img.src"
              [caption]="img.name"
              [hasPriority]="true"
            />
          }
        }
      </drag-scroll>
    </section>
  `,
  styles: ``
})
export class ScreenshotCarouselComponent {
  imgs = [
    { name: 'Label spans! 👌', src: 'assets/img/screenshot-span-labeled.webp' },
    {
      name: 'Get automatic suggestions! ⚡',
      src: 'assets/img/screenshot-span-suggestions.webp'
    },
    {
      name: 'Classify texts 👍',
      src: 'assets/img/screenshot-text-classification.webp'
    },
    {
      name: 'Find and share Corpora! ❤️',
      src: 'assets/img/screenshot-corpora.webp'
    },
    {
      name: 'Easily reuse Label Sets 😊',
      src: 'assets/img/screenshot-label-sets.webp'
    }
  ];
}
