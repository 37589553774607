import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import {
  enableProdMode,
  ErrorHandler,
  importProvidersFrom,
  provideExperimentalZonelessChangeDetection
} from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig
} from '@angular/router';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';

import { DataStandComponent } from '@/data-stand.component';
import { environment } from '@/environments';
import { ROUTES } from '@/routes';
import { GlobalErrorHandler } from '@/shared/error-handlers/global-error-handler';
import { HasHttpRequestInterceptor } from '@/shared/interceptors/has-http-request.interceptor';
import { TokenInterceptor } from '@/shared/interceptors/token.interceptor';

if (environment.production) enableProdMode();

bootstrapApplication(DataStandComponent, {
  providers: [
    provideRouter(
      ROUTES,
      withRouterConfig({
        paramsInheritanceStrategy: 'always'
      }),
      withPreloading(QuicklinkStrategy),
      // Scroll to top when navigating to a new route.
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
        anchorScrolling: 'enabled'
      })
    ),
    // TODO(gustavoauma): Update this when the feature is stable.
    provideExperimentalZonelessChangeDetection(),
    importProvidersFrom(
      BrowserModule,
      QuicklinkModule,
      // Dev/prod conditioned imports.
      environment.imports
    ),
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    // Set default duration for the snack-bar.
    // https://material.angular.io/components/snack-bar/overview
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 3000,
        horizontalPosition: 'left'
      }
    },
    // Use the outline appearance for all form fields.
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', subscriptSizing: 'dynamic' }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    // Used to show a progress bar when an HTTP request is in progress.
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HasHttpRequestInterceptor,
      multi: true
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi())
  ]
}).catch((err) => console.error(err));
